import { NavLink } from 'react-router-dom';
import { WeverCoreContext, _translate as t } from '@/services/core';
import { RoutesPath } from '@/components/core/helpers';
import { useContext } from 'react';
import MenuItem from '@/components/menu/menu-item';
import UserCommunities from './communities/communities';
import { useApp } from '@/contexts/main';
import crosshairs from '@/assets/img/icons/common/location-crosshairs-solid.svg';
import clipboard from '@/assets/img/icons/common/assignment_black_24dp.svg';
import poweredBy from '@/assets/img/icons/common/powered-by-vertical.png';

const UserSidebar = () => {
  const { appValues: state, dispatch } = useApp();
  const { isSideBarExpanded, setIsSideBarExpanded } = useApp();
  const { theme }: any = useContext(WeverCoreContext);
  const { selectedCommunity } = state;

  const handleExpand = () => {
    setIsSideBarExpanded(!isSideBarExpanded);
  };

  const getQueryString = (url: string | undefined) => {
    const parts = url?.split('?');
    if (parts && parts?.length > 1) {
      return encodeURI(parts[1]);
    } else {
      return '';
    }
  };

  return (<>
    <div className={isSideBarExpanded ? 'sidebarBackgroundExpanded' : 'sidebarBackground'} />
    <div className='sidebar'>
      <button
        onClick={handleExpand}
        className='expandButton'
        style={{
          backgroundColor: theme?.primaryColor,
          transform: isSideBarExpanded
            ? 'translate(0px, 0px) rotate(0deg)'
            : 'translate(-220px, 0px) rotate(180deg)',
        }}
      >
        &#x3c;&#x3c;{' '}
      </button>
      <div>
        <NavLink
          to={RoutesPath.HomePage}
          className='d-flex justify-content-center pt-10'
          style={{ width: isSideBarExpanded ? '100%' : '20%' }}
        >
          {
            <img
              id='imgWeverNav'
              src={selectedCommunity?.logo as string}
              className='logo-wever'
              alt='logo'
            />
          }
        </NavLink>
        <div>
          <MenuItem
            srcImg={clipboard}
            navLinkTo={RoutesPath.HomePage}
            title={t('userapp_title_home')}
          />
          {Array.isArray(state?.menu) &&
            state?.menu?.map((item, index: number) => (
              <MenuItem
                key={index}
                srcImg={item.icon}
                navLinkTo={`${item.path}?index=${index}&${getQueryString(item.url)}`}
                title={t(item.title)}
                onClick={() => item.url && item.type === "iframe" && item.path === `/${RoutesPath.ServiceReservation}` && dispatch({ type: 'iframeUrl', value: item.url ?? '' })}
                itemIndex={index}
              />
            ))}
          {Array.isArray(state?.selectedCommunity?.menu) &&
            state?.selectedCommunity?.menu?.map((item, index: number) => (
              <MenuItem
                key={index}
                srcImg={item.icon}
                navLinkTo={`${item.path}?index=${state?.menu && state?.menu.length > 0 ? state?.menu.length + index : index}&${getQueryString(item.url)}`}
                title={t(item.title)}
                onClick={() => item.url && item.type === "iframe" && item.path === `/${RoutesPath.ServiceReservation}` && dispatch({ type: 'iframeUrl', value: item.url ?? '' })}
                itemIndex={state?.menu && state?.menu.length > 0 ? state?.menu.length + index : index}
              />
            ))}
          <hr style={{ width: isSideBarExpanded ? '100%' : '22%' }} />
          <UserCommunities />
        </div>
      </div>
      {isSideBarExpanded && (
        <span className='poweredByWeverPart'>
          <img alt='powered by wever' width='90%' src={poweredBy} />
        </span>
      )}
    </div>
  </>);
};

export default UserSidebar;
