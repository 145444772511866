import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';

import { WeverCoreContext } from '@/services/core';
import { _translate } from '@/services/core';

import CustomInput from '@/components/common/CustomInput';
import useModal from '@/components/layout/sidebar/communities/hooks/use-modal.hook';
import { useContext } from 'react';
interface modalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdate: (value: string) => void;
}

const CommunitiesModal = ({ open, setOpen, onUpdate }: modalProps) => {
  const { handleSaveCommunity, handleChange, inputPrivateCommunityKey, loading, toggle } = useModal(
    onUpdate,
    setOpen,
  );
  const { theme }: any = useContext(WeverCoreContext);

  return (
    <div>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 512 512'
        width='25'
        height='25'
        onClick={() => setOpen(!open)}
        style={{ fill: theme?.secondaryColor, cursor: 'pointer' }}
      >
        <path d='M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z' />
      </svg>

      <Modal isOpen={open} toggle={() => setOpen(!open)}>
        <ModalHeader toggle={toggle}>
          <span>
            {_translate('userapp_add_community_title')}
          </span>
        </ModalHeader>
        <ModalBody>
          {loading ? (
            <Spinner size='sm' />
          ) : (
            <>
              <p>
                {_translate('userapp_add_community_modal_title')}
              </p>
              <CustomInput type='text' value={inputPrivateCommunityKey} onChange={handleChange} />
            </>
          )}
        </ModalBody>
        <ModalFooter className='justify-content-center'>
          <Button
            className='btn-modal-community'
            style={{ backgroundColor: theme?.secondaryColor }}
            disabled={loading}
            onClick={async () => {
              await handleSaveCommunity();
              setOpen(false);
            }}
          >
            {_translate('userapp_save_community')} {loading && <Spinner size='sm' />}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default CommunitiesModal;
